/* eslint-disable */

<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-3/5">
        <vx-card>
          <h1 class="font-light mb-2">💻 Donald's HP Laptop - Task Manager</h1>
          <img
            style="border-style: solid; border-color: #ff7451"
            src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/371305280681541632/task-manager.png"
            class="w-full rounded-lg"
          />
        </vx-card>
      </div>
      <div class="vx-col w-2/5">
        <vx-card v-if="currentTab === 0">
          <img
            src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/371305280681541632/ticketing-system.png"
            class="w-full rounded-lg"
          />

          <div style="padding-bottom: 10px">
            <span></span>
          </div>
          <div class="flex items-center">
            <h3 class="font-bold">
              🎫 Ticket ID: <span class="font-light">#{{ Math.floor(100000 + Math.random() * 900000) }}</span>
            </h3>
          </div>
          <div class="flex items-center mt-4">
            <h3 class="font-bold">📦 Category: <span class="font-light">Corporate Hardware</span></h3>
          </div>
          <div class="flex items-center mt-4">
            <h3 class="font-bold">
              🧑 Assigned To: <span class="font-light">{{ activeUserInfo.email }}</span>
            </h3>
          </div>
          <div class="flex items-center mt-4">
            <h3 class="font-bold">
              📅 Assigned Date (m/d/y):
              <span class="font-light">0{{ Math.floor(1 + Math.random() * 8) }}/0{{ Math.floor(1 + Math.random() * 8) }}/2022</span>
            </h3>
          </div>
          <div class="flex items-center mt-4">
            <h3 class="font-bold">🔴 Priority: <span class="font-light text-warning">Urgent</span></h3>
          </div>
          <div class="w-full rounded mt-4 mb-4" style="height: 4px; background: #ffffff"></div>
          <div class="flex items-center">
            <h3 class="font-bold">
              📧 Subject:
              <span class="font-light"
                >Donald submitted a ticket requesting support for his Work Laptop. He mentions that his work laptop is consistently
                bottle-necking and overheating these past few weeks. He included that he does not power down his laptop due to multiple
                unsaved files.</span
              >
            </h3>
          </div>
          <div class="mt-4">
            <h3 class="font-bold mb-2">Select Root Cause:</h3>
            <v-select placeholder="Select an option from the dropdown" class="w-3/4" :options="issueOptions" v-model="selectedIssue" clas />
          </div>
          <div class="mt-1">
            <small v-if="selectedIssueCorrect !== null && selectedIssueCorrect === false" class="text-danger font-semibold"
              >This answer is invalid</small
            >
            <small v-if="selectedIssueCorrect !== null && selectedIssueCorrect === true" class="text-success font-semibold"
              >This answer is correct</small
            >
          </div>

          <div class="mt-4">
            <h3 class="font-bold mb-2">Device Investigated:</h3>
            <div class="flex items-center">
              <vs-radio class="font-light mr-4" :key="pc" v-for="pc in causedBy" v-model="selectedCausedBy" :vs-value="pc">{{
                pc
              }}</vs-radio>
            </div>
          </div>
          <div class="mt-1">
            <small v-if="selectedCausedByCorrect !== null && selectedCausedByCorrect === false" class="text-danger font-semibold"
              >This answer is invalid</small
            >
            <small v-if="selectedCausedByCorrect !== null && selectedCausedByCorrect === true" class="text-success font-semibold"
              >This answer is correct</small
            >
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';
import vSelect from 'vue-select';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),

      currentTab: 0,

      issueOptions: [
        'High Resource Utilization',
        'Memory Not Detected',
        'Corrupted System32',
        'Wi-Fi Adapter Not Present',
        'Touchpad Drivers Missing',
        'OS Outdated',
        'Existing Ransomware',
        'SSD Full',
      ],
      causedBy: ['Dell Laptop', 'HP Laptop', 'MSI Laptop', 'Custom PC'],

      selectedIssue: null,
      selectedIssueCorrect: null,

      selectedCausedBy: null,
      selectedCausedByCorrect: null,
    };
  },
  methods: {
    popupImage(url) {
      window.open(
        url,
        'popUpWindow',
        'height=600,width=850,left=150,top=150,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=no',
      );
    },
    markSimulation() {
      let totalScore = 0;

      if (this.selectedIssue === 'High Resource Utilization') {
        totalScore++;
        this.selectedIssueCorrect = true;
      } else {
        this.selectedIssueCorrect = false;
      }

      if (this.selectedCausedBy === 'HP Laptop') {
        totalScore++;
        this.selectedCausedByCorrect = true;
      } else {
        this.selectedCausedByCorrect = false;
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
    changePage(val) {
      if (val === 'next') {
        this.currentPage++;
      }
      if (val === 'previous') {
        this.currentPage--;
      }
    },
  },
  mounted() {},
  components: {
    Prism,
    vSelect,
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
